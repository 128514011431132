import { useCallback } from 'react';
import { useDispatch } from 'react-redux/es/exports';
import Api, { DEFAULT_COLLECTION } from '../../../state/api';
import { fetchDocuments } from '../../../state/KnowledgeSlice';
import Modal from '../Modal';

export default function AddNoteModal({ show, onClose }) {
    const dispatch = useDispatch();

    const uploadText = useCallback(async (event) => {
        event.stopPropagation();
        event.preventDefault();
        const title = event.target.title.value;
        const content = event.target.content.value;
        onClose();
        await Api.uploadText(title, content);
        dispatch(fetchDocuments(DEFAULT_COLLECTION));
    }, [dispatch, onClose]);

    return (
        <Modal show={show} onClose={onClose}>
            <form onSubmit={uploadText} className="relative">
                <div className="overflow-hidden rounded-lg border-0 border-dashed border-blue-lightest shadow-sm bg-blue text-white">
                    <label htmlFor="title" className="sr-only">
                        Title
                    </label>
                    <input
                        type="text"
                        name="title"
                        id="title"
                        className="block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-blue-lightest focus:ring-0 bg-blue"
                        placeholder="Title"
                    />
                    <label htmlFor="description" className="sr-only">
                        Content
                    </label>
                    <div className="border-t-2 border-blue-dark mx-2"></div>
                    <textarea
                        rows={2}
                        name="content"
                        id="content"
                        className="block w-full h-48 resize-none border-0 placeholder:text-blue-lightest focus:ring-0 sm:text-sm sm:leading-6 bg-blue"
                        placeholder="Write a note..."
                        defaultValue={''}
                    />
                </div>

                <div className="flex items-center justify-end mt-2">
                    <button
                        type="submit"
                        className="w-16 block rounded-md bg-blue px-3 py-2 text-center text-sm font-semibold text-white hover:bg-blue-light mr-2"
                    >
                        Add
                    </button>
                    <button
                        type="button"
                        onClick={onClose}
                        className="w-16 block rounded-md bg-blue px-3 py-2 text-center text-sm font-semibold text-white hover:bg-blue-light"
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </Modal>
    )
}
