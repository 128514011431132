import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import Human from './../../../images/human.jpeg';
import Vicuna from './../../../images/vicuna.jpeg';
import Agent1 from './../../../images/agent1.jpeg';
import Agent2 from './../../../images/agent2.jpeg';
import Agent3 from './../../../images/agent3.jpeg';
import { DocumentTextIcon } from '@heroicons/react/24/outline'
import { collapseHistory, historySelector, pendingRequestSelector, sendersSelector } from "../../../state/ChatSlice";
import { AvatarName } from "../../../state/GeneralSlice";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import ShowSources from "./ShowSources";

const avatars = {
    [AvatarName.HUMAN]: {
        textColor: "text-azure-pastel",
        icon: Human
    },
    [AvatarName.VICUNA]: {
        textColor: "text-orange-pastel",
        icon: Vicuna
    },
    [AvatarName.AGENT1]: {
        textColor: "text-green-pastel",
        icon: Agent1
    },
    [AvatarName.AGENT2]: {
        textColor: "text-yellow-pastel",
        icon: Agent2
    },
    [AvatarName.AGENT3]: {
        textColor: "text-pink-pastel",
        icon: Agent3
    },
}

export default function History() {

    const history = useSelector(historySelector)
    const senders = useSelector(sendersSelector)
    const pendingRequest = useSelector(pendingRequestSelector)

    const collapsed = useMemo(() => {
        return collapseHistory(history, senders);
    }, [senders, history])

    const historyLength = useMemo(() => {
        return history.map(m => m.message.length).reduce((x, y) => x + y, 0);
    }, [history]);

    useEffect(() => {
        const endOfChat = document.getElementById("end-of-chat");
        if (endOfChat) {
            endOfChat.scrollIntoView({ behavior: 'smooth' });
        }
    }, [historyLength]);

    const [showSources, setShowSources] = useState(false);
    const [selectedSources, setSelectedSources] = useState(undefined);

    return <div id="messages" className="flex-grow flex-shrink space-y-4 p-3 overflow-y-auto">
        {collapsed.map((item, idx) => {
            const senderClassNamesFirstDiv = item.sender.key === AvatarName.HUMAN ? "justify-end" : "";
            const senderClassNamesSecondDiv = item.sender.key === AvatarName.HUMAN ? "order-1 items-end" : "order-2 items-start";
            const senderClassNamesMessage = item.sender.key === AvatarName.HUMAN ? "rounded-br-none" : "rounded-bl-none";
            const senderClassNamesImage = item.sender.key === AvatarName.HUMAN ? "order-2" : "order-1";

            return <div className="chat-message" key={idx}>
                <div className={"flex items-end " + senderClassNamesFirstDiv}>
                    <div className={"flex flex-col space-y-2 text-sm max-w-[75%] mx-2 " + senderClassNamesSecondDiv}>
                        {item.messages.map((msg, idx) => {
                            return <div key={idx}>
                                <span className={"px-4 py-2 rounded-lg inline-block bg-blue " + senderClassNamesMessage}>
                                    <div className="flex">
                                        {idx === 0 && <p className={"flex-grow text-xs font-bold " + avatars[item.sender.key].textColor}>{item.sender.name}</p>}
                                        {msg.sources && <DocumentTextIcon
                                            className="w-4 h-4 stroke-blue-lightest hover:stroke-white cursor-pointer"
                                            onClick={() => {
                                                setSelectedSources(msg.sources)
                                                setShowSources(true);
                                            }}
                                        />}
                                    </div>
                                    {!msg.text && <p className="font-thin text-sm italic">Vicuña is thinking...</p>}
                                    <ReactMarkdown
                                        components={{
                                            ol: ({node, ...props}) => <ol className="list-decimal" {...props} />,
                                            li: ({node, ...props}) => <li className="ml-6 my-3" {...props} />
                                        }}
                                        children={msg.text} />
                                </span>
                            </div>
                        })}
                    </div>
                    <img
                        src={avatars[item.sender.key].icon}
                        alt={item.sender.name}
                        className={"w-10 h-10 rounded-full " + senderClassNamesImage}
                    />
                </div>
            </div>
        })}
        <ShowSources
            show={showSources}
            onClose={() => {
                setShowSources(false);
            }}
            sources={selectedSources}
        />
        <div id={"end-of-chat"} />
    </div>
}