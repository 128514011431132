import { Route, Routes } from "react-router-dom";
import Home from "./content/Home";
import Chat from "./content/Chat";
import Agents from "./content/Agents";
import NoPage from "./content/NotFound";
import KnowledgeBase from "./content/KnowledgeBase";
import Settings from "./content/Settings";

export default function Content() {
    return <span>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="chat" element={<Chat />} />
            <Route path="knowledgeBase" element={<KnowledgeBase />} />
            <Route path="agents" element={<Agents />} />
            <Route path="settings" element={<Settings />} />
            <Route path="*" element={<NoPage />} />
        </Routes>
    </span>
}