import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api from "./api";

const SortProperty = {
    ID: 'id',
    NAME: 'filename',
    TITLE: 'title',
    TYPE: 'type',
    PAGES: 'pages',
    CHUNKS: 'chunks',
}

const initialState = {
    loading: false,
    documents: [],
    filtering: {
        pattern: '',
        ignoreCase: true,
    },
    sorting: {
        property: SortProperty.TITLE,
        descending: true,
    },
};

const fetchDocuments = createAsyncThunk(
    'fetchDocuments',
    async (collectionName) => {
        return await Api.fetchDocuments(collectionName);
    }
)

const knowledgeSlice = createSlice({
    name: 'knowledge',
    initialState,
    reducers: {
        setFilteringPattern: (state, action) => {
            state.filtering.pattern = action.payload;
        },
        setFilteringIgnoreCase: (state, action) => {
            state.filtering.ignoreCase = action.payload;
        },
        setSortingProperty: (state, action) => {
            state.sorting.property = action.payload;
            sortDocuments(state);
        },
        setSortingDescending: (state, action) => {
            state.sorting.descending = action.payload;
            sortDocuments(state);
        },
        remove: (state, action) => {
            const docId = action.payload;
            const doc = state.documents.find(d => d.name === docId);
            if (doc) {
                const idx = state.documents.indexOf(doc);
                state.documents.splice(idx, 1);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDocuments.pending, (state, { meta }) => {
                state.loading = true;
            })
            .addCase(fetchDocuments.fulfilled, (state, { meta, payload }) => {
                state.documents = payload.map((doc) => {
                    return {
                        ...doc,
                        id: doc.filename?.substring(0, 36),
                        filename: doc.filename?.substring(37),
                        type: doc.filename?.split('.').pop().toUpperCase()
                    }
                });
                sortDocuments(state);
                state.loading = false;
            })
            .addCase(fetchDocuments.rejected, (state, action) => {
                console.log("Rejected...")
                state.loading = false;
            })
    },
});

function sortDocuments(state) {
    const { property, descending } = state.sorting;
    state.documents.sort((a, b) => {
        if (a[property] > b[property]) return descending ? 1 : -1;
        if (a[property] < b[property]) return descending ? - 1 : 1;
        return 0;
    });
}

function filterDocuments(state) {
    const { pattern, ignoreCase } = state.filtering;
    let regex = new RegExp(`${pattern}`, ignoreCase ? 'i' : '');
    return state.documents.filter((doc) => regex.test(doc.filename) || regex.test(doc.title));
}

// this is for dispatch
export const { setFilteringPattern, setFilteringIgnoreCase, setSortingProperty, setSortingDescending, remove } = knowledgeSlice.actions;
export { fetchDocuments };

export const documentsSelector = (state) => state[knowledgeSlice.name].documents;
export const filteredDocumentsSelector = (state) => filterDocuments(state[knowledgeSlice.name]);
export const documentsRangeSelector = (state, startIdx, number) => state[knowledgeSlice.name].documents.slice(startIdx, startIdx + number);
export const filteredDocumentsRangeSelector = (state, startIdx, number) => filterDocuments(state[knowledgeSlice.name]).slice(startIdx, startIdx + number);
export const documentsCountSelector = (state) => state[knowledgeSlice.name].documents.length;
export const filteringPatternSelector = (state) => state[knowledgeSlice.name].filtering.pattern;
export const sortingPropertySelector = (state) => state[knowledgeSlice.name].sorting.property;
export const sortingDescendingSelector = (state) => state[knowledgeSlice.name].sorting.descending;

export const selectLoading = (state) => state.knowledge.loading;

// this is for configureStore
export default knowledgeSlice.reducer;