import { configureStore } from '@reduxjs/toolkit';
import {checkAndRefreshToken, login} from "./state/GeneralSlice";
import generalReducer from "./state/GeneralSlice";
import chatReducer from "./state/ChatSlice";
import knowledgeReducer, {fetchDocuments} from './state/KnowledgeSlice';
import listenerMiddleware from "./state/ListenerMiddleware";
import userSlice from "./state/UserSlice";

const store = configureStore({
    reducer: {
        general: generalReducer,
        chat: chatReducer,
        knowledge: knowledgeReducer,
        user: userSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});

store.dispatch(checkAndRefreshToken())

export default store;