import { useCallback } from 'react';
import { useDispatch } from 'react-redux/es/exports';
import Api, { DEFAULT_COLLECTION } from '../../../state/api';
import { fetchDocuments } from '../../../state/KnowledgeSlice';
import Modal from '../Modal';

export default function AddWebUrlModal({ show, onClose }) {
    const dispatch = useDispatch();

    const uploadText = useCallback(async (event) => {
        event.stopPropagation();
        event.preventDefault();
        const url = event.target.url.value;
        onClose();
        console.log(url);
        //await Api.uploadText(title, content);
        dispatch(fetchDocuments(DEFAULT_COLLECTION));
    }, [dispatch, onClose]);

    return (
        <Modal show={show} onClose={onClose}>
            <form onSubmit={uploadText} className="relative">
                <label htmlFor="url" className="text-white text-lg font-medium">
                    Insert URL
                </label>
                <div className="overflow-hidden rounded-lg border-0 border-dashed border-blue-lightest shadow-sm bg-blue text-white">
                    <input
                        type="text"
                        name="url"
                        id="url"
                        className="block w-full border-0 pt-2.5 text-sm placeholder:text-blue-lightest focus:ring-0 bg-blue"
                        placeholder="URL"
                    />
                </div>

                <div className="flex items-center justify-end mt-2">
                    <button
                        type="submit"
                        className="w-16 block rounded-md bg-blue px-3 py-2 text-center text-sm font-semibold text-white hover:bg-blue-light mr-2"
                    >
                        Add
                    </button>
                    <button
                        type="button"
                        onClick={onClose}
                        className="w-16 block rounded-md bg-blue px-3 py-2 text-center text-sm font-semibold text-white hover:bg-blue-light"
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </Modal>
    )
}
