import React, { useCallback } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {login, selectUserState, UserState} from "../../../state/GeneralSlice";

export default function Authenticate({ onRegister = () => { } }) {
    const dispatch = useDispatch();
    const userState = useSelector(selectUserState)

    const onSubmit = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
        const username = event.target.username?.value;
        const password = event.target.password?.value;
        dispatch(login({ username, password }))
    }, [dispatch])

    return <div>
        <form className="space-y-6" onSubmit={onSubmit}>
            <div>
                <label htmlFor="username" className="block text-sm font-medium leading-6 text-blue-lightest">
                    Username
                </label>
                <div className="mt-2">
                    <input
                        id="username"
                        name="username"
                        type="text"
                        autoComplete="username"
                        required
                        className="block bg-blue-dark w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-blue-light placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-lightest sm:text-sm sm:leading-6"
                    />
                </div>
            </div>

            <div>
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-blue-lightest">
                    Password
                </label>
                <div className="mt-2">
                    <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="block bg-blue-dark w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-blue-light placeholder:text-blue-light focus:ring-2 focus:ring-inset focus:ring-blue-lightest sm:text-sm sm:leading-6"
                    />
                </div>
            </div>

            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 bg-blue-dark rounded text-blue border-0 ring-1 ring-inset ring-blue-light focus:ring-2 focus:ring-inset focus:ring-blue-lightest"
                    />
                    <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-blue-lightest">
                        Remember me
                    </label>
                </div>

                <div className="text-sm leading-6">
                    <a href="#" className="font-semibold text-blue-lightest hover:text-white">
                        Forgot password?
                    </a>
                </div>
            </div>

            {userState === UserState.LOGGING_ERROR && <p className="text-orange text-center text-sm">Wrong username or password</p>}

            <div>
                <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-dark"
                >
                    Sign in
                </button>
            </div>
        </form>

        <div>
            <div className="relative mt-6">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-blue-light" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                    <span className="bg-blue-dark px-6 text-blue-lightest">Or continue with</span>
                </div>
            </div>

            <div className="mt-6 grid grid-cols-2 gap-4">
                <a
                    href="#"
                    className="flex w-full items-center justify-center gap-3 rounded-md bg-[#1D9BF0] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]"
                >
                    <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                    <span className="text-sm font-semibold leading-6">Twitter</span>
                </a>

                <a
                    href="#"
                    className="flex w-full items-center justify-center gap-3 rounded-md bg-[#24292F] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]"
                >
                    <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            fillRule="evenodd"
                            d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <span className="text-sm font-semibold leading-6">GitHub</span>
                </a>
            </div>

            <div className="mt-4">
                <button
                    type="button"
                    onClick={onRegister}
                    className="flex w-full justify-center rounded-md bg-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-dark"
                >
                    Register
                </button>
            </div>
        </div>

        <p className="mt-10 text-center text-sm text-blue-lightest">
            Not a member?{' '}
            <a href="#" className="font-semibold leading-6 text-blue-lightest hover:text-white">
                Start a 14 day free trial
            </a>
        </p>
    </div>
}