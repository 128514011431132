import Human from './../images/human.jpeg';

export default function Avatar() {
    return <>
        <span className="sr-only">Your profile</span>
        <img
            className="h-12 w-12 rounded-full stroke-azure stroke-2"
            src={Human}
            alt="Profile Image"
        />
    </>
}