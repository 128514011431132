import { Fragment, useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ask, pendingRequestSelector } from "../../../state/ChatSlice";
import { Transition } from "@headlessui/react";
import { useMediaQuery } from "react-responsive";
import ReactTextareaAutosize from "react-textarea-autosize";

export default function Prompt() {
    const dispatch = useDispatch()
    const promptRef = useRef();
    const [value, setValue] = useState('');
    const pendingRequest = useSelector(pendingRequestSelector)
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    const submit = useCallback(() => {
        const inputRef = promptRef.current;

        if (value.trim() === "")
            return;

        if (pendingRequest) {
            // Popup!
            setIsShowing(true)
            setTimeout(() => setIsShowing(false), 3000)
            return;
        }

        setValue('');
        if (isMobile)
            inputRef.blur();

        dispatch(ask(value))
    }, [dispatch, isMobile, pendingRequest, value])

    const handleSubmit = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        submit();
    }, [submit]);

    const textEnterSubmit = useCallback((event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            event.stopPropagation();
            if (event.ctrlKey || event.metaKey) {
                const textArea = event.target;
                setValue((prev) => prev + '\n')
                textArea.scrollTop = textArea.scrollHeight;
            }
            else {
                submit();
            }
        }
    }, [setValue, submit]);

    const [isShowing, setIsShowing] = useState(false)

    return <div id="chatBar" className="flex-shrink fborder-t-2 border-blue-light px-4 pt-2 mb-2 sm:mb-0">
        <div className="absolute w-0 h-0">
            <Transition
                show={isShowing}
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-y-full opacity-0"
                enterTo="translate-y-0 opacity-100"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-y-0 opacity-100"
                leaveTo="translate-y-full opacity-0"
                className={"absolute"}
            >
                <div className={"relative -top-[5rem] w-[20rem] border-l-4 border-orange text-orange p-2 py-2 mb-2 bg-gradient-to-r from-blue-dark from-75% to-transparent to-100%"} role="alert">
                    <p className="font-bold text-orange-pastel">Be Patient!</p>
                    <p className="text-orange-light">Wait for the Vicuña to answer.</p>
                </div>
            </Transition>
        </div>
        <div className="relative">
            <form onSubmit={handleSubmit} className="w-full flex rounded-md border-0 ring-1 ring-blue-light focus:ring-inset focus:ring-blue-lightest">
                {/*<span className="absolute inset-y-0 flex items-center">*/}
                {/*   <button type="button"*/}
                {/*           className="inline-flex items-center justify-center rounded-full h-12 w-12 transition duration-500 ease-in-out text-blue-lightest hover:bg-blue focus:outline-none">*/}
                {/*      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"*/}
                {/*           className="h-6 w-6">*/}
                {/*         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"*/}
                {/*               d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"></path>*/}
                {/*      </svg>*/}
                {/*   </button>*/}
                {/*</span>*/}
                <ReactTextareaAutosize ref={promptRef} type="text" placeholder="Chat Your Data!" onKeyDown={textEnterSubmit} cacheMeasurements minRows={1} maxRows={4}
                    value={value} onChange={(event) => setValue(event.target.value)}
                    className="w-full resize-none text-white placeholder-blue-lightest bg-blue-dark rounded-l-md py-3 border-0 focus:ring-0" />
                <div className="right-0 items-center inset-y-0 flex">
                    {/*<button type="button"*/}
                    {/*        className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-blue-lightest hover:bg-blue focus:outline-none">*/}
                    {/*    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"*/}
                    {/*         stroke="currentColor" className="h-6 w-6 text-gray-600">*/}
                    {/*        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"*/}
                    {/*              d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"></path>*/}
                    {/*    </svg>*/}
                    {/*</button>*/}
                    <button type="submit"
                        className="inline-flex items-center justify-center rounded-r-md h-full px-2 py-3 h-10 bg-blue-dark transition duration-500 ease-in-out text-blue-lightest hover:bg-blue focus:outline-none">
                        <span className="font-bold text-sm mr-2 hidden sm:block">Send</span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                            className="h-6 w-6 transform rotate-90">
                            <path
                                d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                        </svg>
                    </button>
                </div>
            </form>
        </div>
    </div>
}