import Prompt from "./chat/Prompt";
import History from "./chat/History";
import Reset from "./chat/Reset";

export default function Chat() {
    return <div className="mx-auto max-w-7xl lg:p-2 justify-start flex flex-col items-stretch h-full max-h-full relative">
        <Reset />
        <History/>
        <Prompt/>
    </div>
}
