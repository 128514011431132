import {
    FolderIcon,
    HomeIcon,
    UserGroupIcon, ChatBubbleLeftRightIcon
} from "@heroicons/react/24/outline";

import { ReactComponent as Logo } from './../logotipo.svg';
import Avatar from "./Avatar";
import {NavLink} from "react-router-dom";
import {logout} from "../state/GeneralSlice";
import store from "../store"
import {useSelector} from "react-redux";
import {fullNameSelector} from "../state/UserSlice";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const navigation = [
    { name: 'Home', href: '/', icon: HomeIcon},
    { name: 'Chat Your Data', href: '/chat', icon: ChatBubbleLeftRightIcon},
    { name: 'Knowledge Base', href: '/knowledgeBase', icon: FolderIcon},
    { name: 'Agents', href: '/agents', icon: UserGroupIcon},
]

const settings = [
    { id: 1, name: 'General', href: '/settings', initial: 'G'}
]

export default function Sidebar() {
    const fullName = useSelector(fullNameSelector);

    return <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-blue-dark px-6 w-52">
        <div className="flex h-16 shrink-0 items-center">
            <Logo className="h-8 w-auto fill-white"/>
        </div>
        <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                    <ul role="list" className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                            <li key={item.name}>
                                <NavLink
                                    to={item.href}
                                    className={({ isActive }) => classNames(
                                        isActive
                                            ? 'bg-blue text-white'
                                            : 'text-blue-lightest hover:text-white',
                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                    )}
                                >
                                    <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                    {item.name}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </li>

                <li className="mt-6">
                    <div className="text-xs font-semibold leading-6 text-blue-lightest">Settings</div>
                    <ul role="list" className="-mx-2 mt-2 space-y-1">
                        {settings.map((team) => (
                            <li key={team.name}>
                                <NavLink
                                    to={team.href}
                                    className={({ isActive }) => classNames(
                                        isActive
                                            ? 'bg-blue text-white'
                                            : 'text-blue-lightest hover:text-white',
                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                    )}
                                >
                                  <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                    {team.initial}
                                  </span>
                                    <span className="truncate">{team.name}</span>
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </li>

                <li className="-mx-6 mt-auto">
                    <a
                        href="#"
                        className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-blue"
                        onClick={() => {store.dispatch(logout())}}
                    >
                        <Avatar />
                        <span className="sr-only">Your profile</span>
                        <span aria-hidden="true">{fullName}</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
}