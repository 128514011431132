import {useMemo} from "react";

export default function ProgressBar({value = 12.2, max = 100, unit = "MB"}) {
    const valuePerc = useMemo(() => {
        return value / max * 100.;
    }, [value, max])
    return <div className="relative w-full bg-blue rounded-full text-xs font-medium text-white text-center h-5">
        {valuePerc > 0 && <div className="bg-azure p-0.5 leading-none rounded-full h-full"
             style={{width: valuePerc + "%"}}>
        </div>}
        <div className="absolute top-0.5 left-0 right-0">{value} / {max} {unit}</div>
    </div>
}