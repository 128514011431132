import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import Api from "./api";

const initialState = {
    username: "-",
    email: "-",
    fullName: "-",
    requestCounter: 0,
    requestMax: 500,
    storageMax: 100*1024*1024,
};

const fetchInfo = createAsyncThunk(
    'fetchInfo',
    async () => {
        return await Api.getUserDetails();
    }
)

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        remove: (state, action) => {
            const docId = action.payload;
            const doc = state.documents.find(d => d.name === docId);
            if (doc) {
                const idx = state.documents.indexOf(doc);
                state.documents.splice(idx, 1);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchInfo.pending, (state, {meta}) => {
            })
            .addCase(fetchInfo.fulfilled, (state, {meta, payload}) => {
                state.username = payload.username;
                state.email = payload.email;
                state.fullName = payload.full_name;
            })
            .addCase(fetchInfo.rejected, (state, action) => {
            })
    },
});

export {fetchInfo};

export const fullNameSelector = (state) => state[userSlice.name].fullName;
export const requestCounterSelector = (state) => state[userSlice.name].requestCounter;
export const requestMaxSelector = (state) => state[userSlice.name].requestMax;
export const storageMaxSelector = (state) => state[userSlice.name].storageMax;
export default userSlice.reducer;