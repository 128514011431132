import { useCallback, useContext, useLayoutEffect, useRef, useState } from "react";
import { DOCUMENTS_PER_PAGE, KnowledgeContext } from "../KnowledgeBase";
import { useSelector } from "react-redux";
import { filteredDocumentsRangeSelector } from "../../../state/KnowledgeSlice";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import Api, { API_URL } from "../../../state/api";
import TableHeader from "./TableHeader";

export default function Table() {
    const { selectedDocuments, setSelectedDocuments, pageNumber } = useContext(KnowledgeContext);
    const documents = useSelector(state => filteredDocumentsRangeSelector(state, (pageNumber - 1) * DOCUMENTS_PER_PAGE, DOCUMENTS_PER_PAGE));

    const [checked, setChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const checkbox = useRef();

    useLayoutEffect(() => {
        const isIndeterminate = selectedDocuments.length > 0 && selectedDocuments.length < documents.length;
        setChecked(selectedDocuments.length === documents.length);
        setIndeterminate(isIndeterminate);
        checkbox.current.indeterminate = isIndeterminate;
    }, [checkbox, documents.length, selectedDocuments.length]);

    const toggleAll = useCallback(() => {
        setSelectedDocuments(checked || indeterminate ? [] : documents);
        setChecked(!checked && !indeterminate);
        setIndeterminate(false);
    }, [checked, documents, indeterminate, setSelectedDocuments]);

    return <div className="flex-grow flex-shrink overflow-y-auto w-full flow-root overflow-x-auto max-w-full min-w-full">
        <table className="min-w-full divide-y divide-blue-light">
            <thead className="bg-blue-dark">
                <tr>
                    <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                        <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-blue-light text-blue-light bg-blue-dark focus:ring-0"
                            ref={checkbox}
                            checked={checked}
                            onChange={toggleAll}
                        />
                    </th>
                    <TableHeader name='Title' classes='sm:pl-0' />
                    <TableHeader name='Type' center={true} />
                    <TableHeader name='Pages' center={true} />
                    <TableHeader name='Chunks' center={true} />
                    <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-blue-lightest text-center"
                    >
                        Download
                    </th>
                </tr>
            </thead>

            <tbody className="divide-y divide-blue-light">

                {documents.map((item, idx) => (
                    <tr key={idx} className="hover:bg-blue">
                        <td className="relative px-7 sm:w-12 sm:px-6">
                            {selectedDocuments.includes(item) && (
                                <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                            )}
                            <input
                                type="checkbox"
                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-blue-light text-blue-light bg-blue-dark focus:ring-0"
                                value={item.name}
                                checked={selectedDocuments.includes(item)}
                                onChange={(e) =>
                                    setSelectedDocuments(
                                        e.target.checked
                                            ? [...selectedDocuments, item]
                                            : selectedDocuments.filter((d) => d !== item)
                                    )
                                }
                            />
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                            {item.title}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-white text-center">
                            {item.type}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-white text-center">
                            {item.pages ? item.pages : "-"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-white text-center">
                            {item.chunks}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-white text-center">
                            <a target="_blank" href={API_URL + "/collections/download-file?access_token=" + Api.readToken() + "&file_id=" + item.id+"_"+item.filename} className="flex" rel="noreferrer">
                                <ArrowDownTrayIcon className="h-5 w-full stroke-blue-lightest hover:stroke-white" />
                            </a>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
}