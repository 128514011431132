import axios from "axios";

const API_URL = 'https://prooompt.fly.dev'
//const API_URL = 'https://proomptdev.fly.dev'
const DEFAULT_COLLECTION = 'inputs'

axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';

let askController = undefined;

function storeToken(token) {
    localStorage.setItem(
        "access_token",
        token
    )
}

function readToken() {
    return localStorage.getItem("access_token");
}

// Set config defaults when creating the instance
const instance = axios.create({
    baseURL: API_URL,
});

instance.interceptors.request.use(function (config) {
    const token = readToken();
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});


async function login(username, password) {
    const response = await instance.post('security/token', {
        username: username,
        password: password,
    },
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    storeToken(response.data?.access_token);
    return response;
}

async function checkAndRefreshToken() {
    const token = readToken();
    if (!token) {
        return false;
    }
    const response = await instance.post('security/token/refresh');
    return response.status === 200;
}

async function logout() {
    await instance.get('security/logout')
    storeToken("");
    return
}

function parseMultipleJson(string) {
    let start = string.indexOf("{");
    let open = 0;
    const res = [];
    for (let i = start; i < string.length; i++) {
        if ((string[i] === "{") && (i < 2 || string.slice(i - 2, i) !== "\\\"")) {
            open++
            if (open === 1) {
                start = i
            }
        } else if ((string[i] === "}") && (i < 2 || string.slice(i - 2, i) !== "\\\"")) {
            open--;
            if (open === 0) {
                res.push(JSON.parse(string.substring(start, i + 1)));
                start = i + 1
            }
        }
    }
    return {jsonsInString: res, tail: open > 0 ? string.substring(start) : ""}
}

function readJSON(reader) {
    return {
        async*[Symbol.asyncIterator]() {
            let readResult = await reader.read();
            let stringBuffer = "";
            while (!readResult.done) {
                const string = String.fromCharCode(...readResult.value)
                const {jsonsInString, tail} = parseMultipleJson(stringBuffer ? stringBuffer + string : string)
                stringBuffer = tail;
                yield jsonsInString;
                readResult = await reader.read();
            }
        },
    };
}


//QUESTIONS
async function ask(input, history, dispatch) {
    askController = new AbortController();
    const token = readToken();
    const result = fetch(API_URL + '/questions/stream', {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
            question: input,
            history: history ? history : [],
            // FIXME: Active index?
            active_index: DEFAULT_COLLECTION,
            use_filtering: false
        }),
        signal: askController.signal
    })
        .then(async (response) => {
            // response.body is a ReadableStream
            const reader = response.body.getReader();
            for await (const jsons of readJSON(reader)) {
                for (const json of jsons) {
                    try {
                        dispatch(json);
                    } catch (e) {
                        console.log(e)
                    }
                }
            }
        });
    return await result;
}

async function fetchDocuments(collection) {
    const response = await instance.get('collections/' + collection + "/documents-list");
    return response.data;
}

async function uploadDocument(file) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("title", file.name);
    formData.append("delete_after_upload", false);
    formData.append("paged_ingestion", true);
    await instance.post('collections/uploadfile', formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

    return;
}

async function uploadText(title, content) {
    const formData = new FormData();
    formData.append("markdown_text", content);
    formData.append("title", title);
    formData.append("delete_after_upload", false);
    formData.append("metadata", '{}');
    await instance.post('collections/uploadtext', formData,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });

    return;
}

async function deleteDocument(collectionName, filename) {
    await instance.delete('collections/' + collectionName + "/documents", {
        data: [["source_file", filename]]
    },
        {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    return;
}

async function getUserSettings() {
    const response = await instance.get('security/user-config');
    return response.data;
}

async function saveUserSettings(model, retrieval) {
    await instance.post(
        'security/user-config',
        {
            "llm_model": model,
            "retrieval_top_k": retrieval
        },
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )
}

async function registerUser(fullname, username, email, password) {
    await instance.post(
        'security/register',
        {
            "username": username,
            "email": email,
            "full_name": fullname,
            "disabled": false,
            "organization": {
                "name": "",
                "description": "",
                "disabled": true
            },
            "config": {
                "llm_model": "text-davinci-003",
                "retrieval_top_k": 5
            },
            "password": password
        },
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )
}

async function getUserDetails() {
    const response = await instance.get('security/me')
    return response.data;
}
async function editUser(fullname, username, email, password) {
    const details = await getUserDetails();
    await instance.post(
        'security/edit-user',
        {
            "username": username,
            "email": email,
            "full_name": fullname,
            "disabled": details.disabled,
            "organization": {
                "name": details.organization.name,
                "description": details.organization.description,
                "disabled": details.organization.disabled,
            },
            "config": {
                "llm_model": details.config.llm_model,
                "retrieval_top_k": details.config.retrieval_top_k
            },
            "password": password
        },
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )
}

function abortAsk() {
    if (askController) {
        askController.abort();
        askController = undefined;
    }
}

async function downloadFile(file) {
    const response = await instance.get(
        'collections/download-file',
        {
            responseType: "arraybuffer",
            params: { file_id: file }
        }
    );
    return response.data;
}

export { API_URL, DEFAULT_COLLECTION };
export default {
    login,
    logout,
    ask,
    abortAsk,
    fetchDocuments,
    uploadDocument,
    uploadText,
    deleteDocument,
    checkAndRefreshToken,
    getUserSettings,
    saveUserSettings,
    registerUser,
    getUserDetails,
    editUser,
    downloadFile,
    readToken,
};