import { useCallback, useState } from "react";
import Buttoon from "../../Buttoon";
import { UserCircleIcon } from '@heroicons/react/24/solid'
import Api from "../../../state/api";

export default function Register({ edit = false, onCancel = () => { }, onSubmit = () => { } }) {
    const [input, setInput] = useState({
        email: '',
        confirmEmail: '',
        password: '',
        confirmPassword: ''
    });

    const [error, setError] = useState({
        confirmEmail: '',
        confirmPassword: ''
    })

    const validateInput = useCallback((e) => {
        let { name, value } = e.target;
        setError(prev => {
            const stateObj = { ...prev, [name]: "" };

            switch (name) {
                case "confirmEmail":
                    if (input.email && value !== input.email) {
                        stateObj[name] = "Confirmation email address does not match.";
                    }
                    break;

                case "confirmPassword":
                    if (input.password && value !== input.password) {
                        stateObj[name] = "Confirmation password does not match.";
                    }
                    break;

                default:
                    break;
            }

            return stateObj;
        });
    }, [input.email, input.password]);

    const onInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setInput(prev => ({
            ...prev,
            [name]: value
        }));
        validateInput(e);
    }, [validateInput]);

    const handleSubmit = useCallback(async (event) => {
        event.stopPropagation();
        event.preventDefault();
        const fulllname = event.target.fullname.value;
        const username = event.target.username.value;
        const email = event.target.email.value;
        const password = event.target.password.value;
        if (edit)
            await Api.editUser(fulllname, username, email, password);
        else
            await Api.registerUser(fulllname, username, email, password);
        onsubmit();
    }, [edit])

    return <div>
        <form onSubmit={handleSubmit} className="space-y-6">
            <div>
                <h2 className="text-lg font-semibold leading-7 text-white text-center">
                    {edit ? 'Edit User Info' : 'User Registration'}
                </h2>
            </div>

            <div className="col-span-full">
                <label htmlFor="photo" className="block text-sm font-medium leading-6 text-blue-lightest">
                    Profile photo
                </label>
                <div className="mt-2 flex items-center gap-x-3">
                    <UserCircleIcon className="h-12 w-12 text-gray-500" aria-hidden="true" />
                    <Buttoon>Change</Buttoon>
                </div>
            </div>

            <div>
                <label htmlFor="username" className="block text-sm font-medium leading-6 text-blue-lightest">
                    Username
                </label>
                <div className="mt-2">
                    <input
                        disabled={edit}
                        id="username"
                        name="username"
                        type="text"
                        required
                        className="block bg-blue-dark w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-blue-light placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-lightest sm:text-sm sm:leading-6"
                    />
                </div>
            </div>

            <div className="border-b border-white/10 pb-6">
                <label htmlFor="fullname" className="block text-sm font-medium leading-6 text-blue-lightest">
                    Fullname
                </label>
                <div className="mt-2">
                    <input
                        id="fullname"
                        name="fullname"
                        type="text"
                        required
                        className="block bg-blue-dark w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-blue-light placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-lightest sm:text-sm sm:leading-6"
                    />
                </div>
            </div>

            <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-blue-lightest">
                    Email address
                </label>
                <div className="mt-2">
                    <input
                        value={input.email}
                        onChange={onInputChange}
                        id="email"
                        name="email"
                        type="text"
                        required
                        className="block bg-blue-dark w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-blue-light placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-lightest sm:text-sm sm:leading-6"
                    />
                </div>
            </div>

            {!edit && (
                <div className="border-b border-white/10 pb-6">
                    <label htmlFor="confirmEmail" className="block text-sm font-medium leading-6 text-blue-lightest">
                        Confirm email address
                    </label>
                    <div className="mt-2">
                        <input
                            value={input.confirmEmail}
                            onChange={onInputChange}
                            onBlur={validateInput}
                            id="confirmEmail"
                            name="confirmEmail"
                            type="text"
                            required
                            className="block bg-blue-dark w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-blue-light placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-lightest sm:text-sm sm:leading-6"
                        />
                        {error.confirmEmail && <div className="text-orange mt-2 text-xs">{error.confirmEmail}</div>}
                    </div>
                </div>
            )}

            <div>
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-blue-lightest">
                    Password
                </label>
                <div className="mt-2">
                    <input
                        value={input.password}
                        onChange={onInputChange}
                        id="password"
                        name="password"
                        type="password"
                        required
                        className="block bg-blue-dark w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-blue-light placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-lightest sm:text-sm sm:leading-6"
                    />
                </div>
            </div>

            {!edit && (
                <div>
                    <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-blue-lightest">
                        Confirm password
                    </label>
                    <div className="mt-2">
                        <input
                            value={input.confirmPassword}
                            onChange={onInputChange}
                            onBlur={validateInput}
                            id="confirmPassword"
                            name="confirmPassword"
                            type="password"
                            required
                            className="block bg-blue-dark w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-blue-light placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-lightest sm:text-sm sm:leading-6"
                        />
                    </div>
                    {error.confirmPassword && <div className="text-orange mt-2 text-xs">{error.confirmPassword}</div>}
                </div>
            )}

            <div className="border-t border-white/10 pt-6 flex items-center justify-end gap-x-3">
                <Buttoon className='w-28' onClick={onCancel} >Cancel</Buttoon>
                <Buttoon className='w-28' type='submit' >{edit ? 'Update' : 'Submit'}</Buttoon>
            </div>
        </form>
    </div>
}