import ProgressBar from "./home/ProgressBar";
import {useSelector} from "react-redux";
import {fullNameSelector, requestCounterSelector, requestMaxSelector, storageMaxSelector} from "../../state/UserSlice";
import {useMemo} from "react";

const textClassName = "text-sm text-blue-lightest py-0.5 "
export default function Home() {
    const fullName = useSelector(fullNameSelector)
    const requestCounter = useSelector(requestCounterSelector)
    const requestMax = useSelector(requestMaxSelector)
    const storageMax = useSelector(storageMaxSelector)

    const storageMaxMB = useMemo(() => {
        return storageMax / (1024*1024);
    }, [storageMax])


    return <div className="pt-2 mx-auto max-w-7xl px-6 lg:px-8">
        <p className="text-2xl lg:text-4xl mb-1">Welcome, {fullName}</p>
        <p className={textClassName}>Get started with globcon AI</p>
        <p className="text-xl lg:text-2xl mb-1 mt-8">Usage overview</p>
        <div className="py-2">
            <p className="mb-1 text-blue-lightest">Requests</p>
            <ProgressBar value={requestCounter} max={requestMax} unit={""}></ProgressBar>
        </div>
        <div className="py-2">
            <p className="mb-1 text-blue-lightest">Storage</p>
            <ProgressBar value={0} max={storageMaxMB} unit="MB"></ProgressBar>
        </div>
    </div>
}