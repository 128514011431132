import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { getUserConfig } from "../../state/GeneralSlice";
import Api from "../../state/api";
import Buttoon from "../Buttoon";
import ModelComboBox from "./settings/ModelComboBox";
import Notification from "./settings/Notification";
import RetrievalComboBox from "./settings/RetrievalComboBox";

export default function Settings() {
    const [showNotification, setShowNotification] = useState(false);
    const dispatch = useDispatch();

    const saveSettings = useCallback(async (event) => {
        event.stopPropagation();
        event.preventDefault();
        const mod = event.target.model.value;
        const ret = event.target.retrieval.value;
        await Api.saveUserSettings(mod, ret);
        setShowNotification(true);
        dispatch(getUserConfig());
    }, [dispatch]);

    return (
        <div className="p-6">
            <form onSubmit={saveSettings} method='post' className="flex flex-col items-center gap-8">
                <ModelComboBox />
                <RetrievalComboBox />
                <Buttoon type="submit" className="mt-6" >Save</Buttoon>
            </form>
            {showNotification && (<Notification onClose={() => setShowNotification(false)} />)}
        </div>
    );
}